/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_appsync_graphqlEndpoint": "https://jye4k253kjg7tdx5uu32xirbpy.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-4rnobptaprgnjalrg6msqp524y",
    "aws_cognito_identity_pool_id": "us-east-1:bf42dde3-be92-4200-b4a5-4260948e6d1c",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_y7Pi9Tshr",
    "aws_user_pools_web_client_id": "6dkgg2p7ovlqk3vrmh4ovkoba4",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_content_delivery_bucket": "raquelastic-20240625184344-hostingbucket-dev",
    "aws_content_delivery_bucket_region": "us-east-1",
    "aws_content_delivery_url": "https://d18v9q5gxgfmi.cloudfront.net"
};


export default awsmobile;
